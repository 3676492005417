import store from "@/store"

const isValidPermission = (permission) => {
    const obj = permission.split(".")
    for (
        let i = 0;
        i < store.state.portal.profile.user_permissions.length;
        i++
    ) {
        if (
            obj[1] ===
                store.state.portal.profile.user_permissions[i].codename &&
            obj[0] ===
                store.state.portal.profile.user_permissions[i].content_type
                    .app_label
        ) {
            return true
        }
    }
    for (let x = 0; x < store.state.portal.profile.groups.length; x++) {
        for (
            let i = 0;
            i < store.state.portal.profile.groups[x].permissions.length;
            i++
        ) {
            if (
                obj[1] ===
                    store.state.portal.profile.groups[x].permissions[i]
                        .codename &&
                obj[0] ===
                    store.state.portal.profile.groups[x].permissions[i]
                        .content_type.app_label
            ) {
                return true
            }
        }
    }
    return false
}

const hasPermission = (permission, rule, condition = "and") => {
    if (store.state.portal.profile.is_superuser) {
        menu.push(rule)
        return true
    }
    if (
        (permission === "company.view_mileage_frontend" ||
            (typeof permission === "object" &&
                permission.includes("company.view_mileage_frontend"))) &&
        (store.state.portal.profile.employee.show_mileage ||
            store.state.portal.profile.employee.mileage)
    ) {
        menu.push(rule)
        return true
    }
    if (
        (permission === "company.view_expenses_frontend" ||
            (typeof permission === "object" &&
                permission.includes("company.view_expenses_frontend"))) &&
        (store.state.portal.profile.employee.show_expense ||
            store.state.portal.profile.employee.expenses)
    ) {
        menu.push(rule)
        return true
    }
    if (
        (permission === "attendance.view_frontend_timesheet" ||
            (typeof permission === "object" &&
                permission.includes("attendance.view_frontend_timesheet"))) &&
        (store.state.portal.profile.employee.show_timesheet ||
            store.state.portal.profile.employee.timesheets)
    ) {
        menu.push(rule)
        return true
    }
    if (typeof permission === "object" && condition === "or") {
        for (let i = 0; i < permission.length; i++) {
            if (isValidPermission(permission[i])) {
                menu.push(rule)
                return true
            }
        }
        return false
    }
    if (typeof permission === "object") {
        const valid = []
        for (let i = 0; i < permission.length; i++) {
            valid.push(isValidPermission(permission[i]))
        }
        let myValid = true
        for (let i = 0; i < valid.length; i++) {
            if (valid[i] === false) {
                myValid = false
            }
        }
        if (myValid) {
            menu.push(rule)
        }
        return myValid
    }
    if (isValidPermission(permission)) {
        menu.push(rule)
        return true
    }
}

var menu = [
    {
        title: "Home",
        route: "home",
        icon: "HomeIcon",
    },
]
// Timesheet
// perms.attendance.view_frontend_timesheet or perms.attendance.view_all_timesheets or perms.attendance.approve_timesheet
hasPermission(
    [
        "attendance.view_frontend_timesheet",
        "attendance.view_all_timesheets",
        "attendance.approve_timesheet",
    ],
    {
        header: "Time",
    },
    "or"
)
// perms.attendance.view_frontend_timesheet
hasPermission("attendance.view_frontend_timesheet", {
    title: "My Attendance",
    // route: 'attendance-my-attendance',
    href: `https://timesheet.damiagroup.pt/attendance/my-attendance/${new Date().getMonth() + 1}/${new Date().getFullYear()}/`,
    icon: "ClockIcon",
})
// perms.attendance.approve_timesheet or perms.attendance.view_all_timesheets
hasPermission(
    ["attendance.approve_timesheet", "attendance.view_all_timesheets"],
    {
        title: "People's Attendance",
        // route: 'attendance-peoples-attendance',
        href: "https://timesheet.damiagroup.pt/attendance/peoples-attendance/",
        icon: "ClipboardIcon",
    },
    "or"
)

// Forms
hasPermission(
    [
        "company.view_mileage_frontend",
        "company.view_all_mileages",
        "company.approve_mileage",
        "company.view_expenses_frontend",
        "company.view_all_expenses",
        "company.approve_expenses",
    ],
    {
        header: "Forms",
    },
    "or"
)
hasPermission("company.view_mileage_frontend", {
    title: "My Mileage",
    route: "forms-mileage",
    icon: "MapIcon",
})
hasPermission(
    ["company.approve_mileage", "company.view_all_mileages"],
    {
        title: "People's Mileage",
        route: "forms-peoples-mileage",
        icon: "MapIcon",
    },
    "or"
)
hasPermission("company.view_expenses_frontend", {
    title: "My Expenses",
    route: "forms-expenses",
    icon: "DollarSignIcon",
})
hasPermission(
    ["company.approve_expenses", "company.view_all_expenses"],
    {
        title: "People's Expenses",
        route: "forms-peoples-expenses",
        icon: "DollarSignIcon",
    },
    "or"
)

// Reports
// perms.finance.view_frontend_finance or perms.attendance.view_timesheet_report
hasPermission(
    [
        "attendance.view_timesheet_report",
        "company.view_mileage_report",
        "company.view_expense_report",
    ],
    {
        header: "Reports",
    },
    "or"
)

// perms.attendance.view_timesheet_report
hasPermission("attendance.view_timesheet_report", {
    title: "Timesheet",
    // route: 'reports-timesheet',
    href: "https://timesheet.damiagroup.pt/reports/timesheet/",
    icon: "ClockIcon",
})

// perms.company.view_mileage_report
hasPermission("company.view_mileage_report", {
    title: "Mileage",
    route: "reports-mileage",
    icon: "MapIcon",
})

// perms.company.view_expense_report
hasPermission("company.view_expense_report", {
    title: "Expense",
    route: "reports-expense",
    icon: "DollarSignIcon",
})

hasPermission(
    [
        "company.view_user_permission",
        "company.view_meta_user_permission",
        "company.view_meta_recruiter_permission",
        "company.view_damia_recruiter_permission",
    ],
    {
        header: "Settings",
    },
    "or"
)

hasPermission(
    [
        "company.view_user_permission",
        "company.view_meta_user_permission",
        "company.view_meta_recruiter_permission",
        "company.view_damia_recruiter_permission",
    ],
    {
        title: "User's Permissions",
        route: "settings-permissions",
        icon: "LockIcon",
    },
    "or"
)

export default menu
